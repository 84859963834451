/* signup.css */

.signup-container {
    max-width: 650px;
    margin: 0 auto;
    padding: 70px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .signup-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 100px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .info-icon {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    color: #888;
  }

  .h1 {
    font-size: 24px;
    color: white
  }
 /* signup.css 
 Contributor Setup Page Styles
 */

 /* signup.css */
.contributor-setup {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contributor-setup h1 {
  font-size: 32px;
  color: #2F4F4F;
  margin-bottom: 30px;
}

.contributor-setup .form-group {
  margin-bottom: 30px;
}

.contributor-setup .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  color: #2F4F4F;
}

.contributor-setup .form-control {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #2F4F4F;
}

.contributor-setup .btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.contributor-setup .btn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 400px;
}



.contributor-setup .btn-primary {
  background-color: #7BC0FF;
  color: #2F4F4F;
}

.contributor-setup .btn-primary:hover {
  background-color: #0074D9;
}

.contributor-setup .btn-secondary {
  background-color: #E1E8ED;
  color: #2F4F4F;
}

.contributor-setup .btn-secondary:hover {
  background-color: #C1D1D9;
}

.contributor-setup .pricing-form {
  margin-top: 40px;
  text-align: left;
}

.contributor-setup .pricing-form h2 {
  font-size: 24px;
  color: #2F4F4F;
  margin-bottom: 20px;
}

.contributor-setup .pricing-form .form-group {
  margin-bottom: 20px;
}

.contributor-setup .pricing-form .btn {
  margin-top: 30px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: auto;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content .form-group {
  margin-bottom: 20px;
  width: 100%;
}

.popup-content .btn {
  margin-top: 20px;
}

.popup-content .btn-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 0.7;
  cursor: not-allowed;
}