.banner-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    gap: 20px;
    margin-top: 40px;
  }
  
  .banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin-bottom: 10px;
  }
  .banner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .banner:hover:before {
    opacity: 0;
  }
  
  .banner {
    width: 300px;
    height: 700px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
  }
  .banner:hover {
    transform: scale(1.05);
  }
  
  .producer-banner {
    background-image: url('images/producer2.jpg');
  }
  
  .songwriter-banner {
    background-image: url('images/lyrics1.jpg');
  }
  
  .vocal-artist-banner {
    background-image: url('images/artist1.jpg');
  }
  
  .banner h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .banner button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  
  .banner p {
    font-size: 18px;
    margin-top: 10px;
    position: relative;
    z-index: 1;
  }