.learn-more-accordion {
    position: fixed;
    bottom: 5px;
    left: 5px;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000; /* Ensure it's above other content */
    opacity: 0.25; /* Initial opacity set to 25% */
    font-size: 0.9em; /* Smaller font size */
}

.learn-more-accordion:hover {
    opacity: 1; /* Full opacity on hover */
}
.learn-more-closed {
    display: flex;
    align-items: center;
    font-size: 1.2em; /* Larger font size */
}

.question-mark-icon {
    font-size: 2em; /* Larger question mark */
    color: #007bff; /* Bright blue color */
    margin-right: 5px;
    transition: transform 0.3s ease; /* Smooth transition for rotation */
}

.learn-more-accordion.open .question-mark-icon {
    transform: rotate(90deg); /* Rotate the question mark when open */
}

.learn-more-open ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left; /* Align text to the left */
}

.learn-more-open li {
    margin-bottom: 10px;
}

.learn-more-accordion.open {
    width: 250px; /* Adjust as needed */
    height: auto; /* Adjust as needed */
    padding: 15px; /* More padding for open state */
}

/* Styling for links inside the accordion */
.learn-more-accordion a {
    color: white; /* White color for links */
    text-decoration: none; /* Remove underline from links */
    transition: color 0.3s ease; /* Transition for hover effect */
}

.learn-more-accordion a:hover {
    color: #007bff; /* Change color on hover */
}
