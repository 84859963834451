@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
para {
    font-family: 'Montserrat', sans-serif;
  }

body {
    font-family: 'Montserrat', sans-serif;
  }

  