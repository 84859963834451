.about-our-team {
    text-align: center;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
}

.team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.team-member {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    width: 420px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f7fa;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the button to the bottom */
    min-height: 320px; /* Adjust the minimum height as needed */
}

.team-member h2 {
    color: #007bff;
}

.team-member h3 {
    color: #333;
}

.team-member p {
    text-align: left;
}

.team-member button {
    align-self: center; /* Center the button */
    margin-top: auto; /* Push the button to the bottom */
}

.about-our-team h1 {
    color: white;
}


.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Blurs the background */
    z-index: 1000; /* Ensures it's above other content */
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001; /* Ensures the popup is above the semi-transparent background */
    max-width: 500px; /* Adjust as needed */
    width: 90%; /* Adjust as needed */
    text-align: center;
}

/* Optional: Animation for the popup */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

.popup-container {
    animation: fadeIn 0.3s ease-out;
}

.company-info {
    background-color: #f5f7fa; /* Matching team member cards */
    border-radius: 10px; /* Consistent with team member cards */
    padding: 20px; /* Consistent padding */
    margin: 20px auto; /* Center the section and add space above and below */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Consistent shadow */
    text-align: center; /* Centered text */
    width: 80%; /* Adjust this value to control the width */
    max-width: 900px; /* Adjust this value to prevent it from becoming too wide */
}

.company-info h1 {
    color: #007bff; /* Matching team member names */
}

.company-info p {
    color: #333; /* Consistent text color */
    text-align: left; /* Align text to the left for readability */
    max-width: 800px; /* Limit width for readability */
    margin: 10px auto; /* Center the paragraphs with some space between */
}