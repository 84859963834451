.rights-licensing {
    font-family: 'Montserrat', sans-serif;
    padding: 15px;
    text-align: center;
}

.rights-licensing h1{
    color: white;
}
 

.rights-licensing.license-type h2 {
    color: #007bff; /* Blue color for h1 and h2 */
    text-align: center;
}


.license-types-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    align-self: center;
}

.license-type {
    text-align: center;
    flex-basis: calc(25% - 20px); /* Adjust the width as needed */
    max-width: calc(25% - 20px); /* Maximum width */
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 15px;
    background-color: #f5f7fa;
    list-style: outside; /* Apply list style directly to .license-type */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.license-type ul, .license-type li {
    margin-bottom: 8px; /* Apply margin to both ul and li */
}
.license-type:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow for pop-out effect */
}


.info-bubble {
    display: inline-block; /* Make it inline to fit content */
    font-weight: 450;
    margin: 20px auto; /* Centering the bubble */
    padding: 10px 20px; /* Padding to give space inside the bubble */
    background-color: #7BC0FF; /* Sky Blue */
    border-radius: 25px; /* Rounded edges */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    font-style: italic;
}

.license-type:hover.advance {
    animation: glow 1.0s infinite alternate; /* Animation for glowing effect */
    border: 1.1px solid #007bff; /* Solid blue border */

}

.license-type.exclusive {
    flex-basis: 100%; /* Exclusive takes full width */
    max-width: 40%;
    border: 3px solid #007bff; /* Solid blue border */
    box-shadow: 0 0 15px #007bff; /* Radiating blue shadow */
    position: relative; /* Required for the glow effect */
    animation: glow 1.0s infinite alternate; /* Animation for glowing effect */
}

/* Keyframes for glow animation */
@keyframes glow {
    from {
        box-shadow: 0 0 10px #007bff;
    }
    to {
        box-shadow: 0 0 20px #007bff;
    }
}