body, html {
    height: 100%; /* Ensure the body and html take the full viewport height */
    margin: 0; /* Reset default margin */
    /*background-color: #E1E8ED;  Light Steel Blue */
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.App {
    min-height: 100vh;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: #2F4F4F; /* Slate Gray */
    height: 100%; /* Make sure .App takes the full height */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    background: linear-gradient(30deg, #001F3F, #007bff, #45A0E6, #87CEEB);
    background-size: 400% 400%; /* Make the gradient larger */
    animation: animatedBackground 15s ease-in-out infinite; /* Adjust time as needed */
    position: relative; /* Add this to create a new stacking context for the noise layer */
}

/* Add a noise layer */
.noise-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%3E%3Crect%20x%3D%220%22%20y%3D%220%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22none%22%20rx%3D%2210%22%20ry%3D%2210%22%3E%3C%2Frect%3E%3Ccircle%20cx%3D%2250%25%22%20cy%3D%2250%25%22%20r%3D%222%22%20fill%3D%22%23007bff%22%20opacity%3D%220.5%22%3E%3C%2Fcircle%3E%3C%2Fsvg%3E');
    background-size: 20px 20px;
    animation: noiseAnimation 10s linear infinite;
    pointer-events: none; /* Prevent the noise layer from intercepting mouse events */
}

/* Add animated balls */
.ball {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #007bff;
    opacity: 0.5;
    animation: ballAnimation 5s ease-in-out infinite;
}

.ball:nth-child(2) {
    animation-delay: 1s;
}

.ball:nth-child(3) {
    animation-delay: 2s;
}

.ball:nth-child(4) {
    animation-delay: 3s;
}

@keyframes animatedBackground {
    0% { background-position: 0% 50%; }
    25% { background-position: 50% 100%; }
    50% { background-position: 100% 50%; }
    75% { background-position: 50% 0%; }
    100% { background-position: 0% 50%; }
}

@keyframes noiseAnimation {
    0% { transform: translate(0, 0); }
    10% { transform: translate(-5%, -5%); }
    20% { transform: translate(5%, 5%); }
    30% { transform: translate(-3%, -3%); }
    40% { transform: translate(3%, 3%); }
    50% { transform: translate(0, 0); }
    60% { transform: translate(-5%, -5%); }
    70% { transform: translate(5%, 5%); }
    80% { transform: translate(-3%, -3%); }
    90% { transform: translate(3%, 3%); }
    100% { transform: translate(0, 0); }
}

@keyframes ballAnimation {
    0% { transform: translate(-50%, -50%) scale(0); opacity: 0; }
    50% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
    100% { transform: translate(-50%, -50%) scale(0); opacity: 0; }
}



h1 {
    font-weight: 600; /* Semi-bold */
  }
  

header {
    display: flex;
    justify-content: space-between; /* This will push children to opposite ends */
    align-items: center;
    padding: 1px 20px;
    background-color: #000024;
    color: #e6ebeb;
}

main {
    margin-top: 50px;
    flex: 1; /* Allow main to grow and take up the remaining space */
    display: flex; /* Turn main into a flex container */
    flex-direction: column; /* Stack children vertically */
    /* justify-content: center; /* Center children horizontally */
    align-items: center;  /*Center children vertically */
   /* background-color: #E1E8ED;  Light Steel Blue */
   font-family: 'Montserrat', sans-serif;
   font-weight: 400;
}


/* Search Box Styles */
/* Search Box Styles */
.search-input {
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
    padding: 12px 18px; /* Slightly larger padding */
    font-size: 18px;
    width: 70%;
    border: 1px solid transparent;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced box shadow */
    border-bottom: 2px solid #7BC0FF; /* Accent color */
    outline: none;
    color: #000909;
    transform: scale(1.02); /* Slightly enlarges the input box */
    border-color: #0074D9; /* Change border color */
    transition: transform 0.3s ease, border-color 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    resize: none; /* Prevent manual resizing */
    overflow: hidden; /* Hide scroll bar */
  }
  
  /* Focus state */
  .search-input:focus {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    border-color: #0074D9; /* Change border color on focus */
    font-family: 'Montserrat', sans-serif;
  }
  
  /* Container Styles */
  .search-container {
    margin-top: 20px;
    position: relative;
    display: flex; /* Turn the container into a flex container */
    align-items: center; /* Center children vertically */
    gap: 10px; /* Space between the input and the button */
    width: 100%; /* Take the full width available */
    max-width: 800px; /* Set a maximum width to prevent it from becoming too wide on large screens */
    font-family: 'Montserrat', sans-serif;
  }
  

input[type="text"]:focus {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Button Styles */
button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding: 10px 20px; 
    font-size: 18px;
    background-color: #7BC0FF; /* Sky Blue */
    color: #2F4F4F; /* Slate Gray */
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0074D9; /* Electric Blue */
}

/* Account Button Styles */
.account-dropdown {
    position: relative;
    display: inline-block;
}

.account-dropdown button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #7BC0FF;
    color: #2F4F4F;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.account-dropdown button:hover {
    background-color: #0074D9;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Dropdown Content Styles */
.dropdown-content {
    font-family: 'Montserrat', sans-serif;
    display: block;
    position: absolute;
    top: calc(100% + 10px); /* Add some space between button and dropdown */
    right: 0;
    background-color: white;
    min-width: 200px;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0,0,0,0.1);
    z-index: 1000;
    overflow: hidden;
    animation: dropdownFade 0.3s ease;
}

/* Animation for dropdown appearance */
@keyframes dropdownFade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdown-content a {
    color: #2F4F4F;
    padding: 14px 20px;
    text-decoration: none;
    display: block;
    transition: all 0.2s ease;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.dropdown-content a:last-child {
    border-bottom: none;
}

.dropdown-content a:hover {
    background-color: #f8f9fa;
    color: #0074D9;
    padding-left: 24px; /* Slide effect on hover */
}

/* Add a subtle arrow */
.dropdown-content::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 20px;
    width: 16px;
    height: 16px;
    background-color: white;
    transform: rotate(45deg);
    box-shadow: -2px -2px 5px rgba(0,0,0,0.04);
}

/* Add hover effect for the entire dropdown */
.dropdown-content:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 28px rgba(0,0,0,0.12);
    transition: all 0.3s ease;
}

/* Add active state for clicks */
.dropdown-content a:active {
    background-color: #e9ecef;
    transform: scale(0.98);
}

.placeholder-container {
    position: absolute;
    top: 15px; /* Adjust based on your input padding */
    left: 15px; /* Adjust based on your input padding */
    overflow: hidden;
    height: 24px; /* Adjust based on your font size */
    pointer-events: none; /* Ensure the input below can be focused */
}

.placeholder-text {
    animation: slideCube 5s infinite; /* Adjust the duration as needed */
    color: #0b2727;
}


@keyframes slideCube {
    0%, 100% {
        transform: translateY(100%);
        opacity: 0;
    }
    10%, 90% {
        transform: translateY(0);
        opacity: 1;
    }
}

.offscreen {
    position: absolute;
    left: -9999px;
}

/* Add this to style the label for the username */
label[for="username"] {
    display: block;
    margin-bottom: 10px; /* Spacing between label and input box */
    font-weight: bold; /* Make the label text bold */
}
label[for="password"] {
    display: block;
    margin-bottom: 10px; /* Spacing between label and input box */
    font-weight: bold; /* Make the label text bold */
}
label[for="confirm_pwd"] {
    display: block;
    margin-bottom: 10px; /* Spacing between label and input box */
    font-weight: bold; /* Make the label text bold */
}
/* New styles for the Register.js text inputs */
.register-input {
    display: block;
    padding: 8px 12px;
    font-size: 16px;
    width: 25%; 
    border: 1px solid #2F4F4F; /* Slate Gray */
    border-radius: 5px;
    background-color: #FFFFFF; /* White */
    color: #2F4F4F; /* Slate Gray */
    outline: none;
    transition: border-color 0.3s ease;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.register-input:focus {
    border-color: #0074D9; /* Electric Blue */
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .errmsg-fadeout {
    animation: fadeOut 2s forwards; /* Animation lasts for 2 seconds */
  }
  
  .successmsg {
    background-color: lightgreen;
    color: darkgreen;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .successmsg-fadeout {
    animation: fadeOut 2s forwards; /* Animation lasts for 2 seconds */
  }
  
/* Loading Bubble Styles */
.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
    gap: 20px; /* Add space between elements */
}

.loading-status {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    background-color: #f8f9fa;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 15px 0;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333;
    font-weight: bold;
    z-index: 1;
}

.progress-step-message {
    width: 100%;
    max-width: 400px;
    margin-top: 15px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.step-message {
    font-size: 14px;
    color: #666;
    line-height: 1.4;
    margin: 0;
    padding: 5px;
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.loading:hover {
    background-color: #0074D9; /* Electric Blue on hover */
}

@keyframes fadeInOut {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}
.Audio-Player {
    margin-top: 30px;
    width: 80%; /* Adjusted width for better screen utilization */
    padding: 15px; /* Slightly more internal padding */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* More refined shadow */
    border-radius: 15px;
    border: 1px solid #7BC0FF; /* Thinner border for a lighter touch */
    background: linear-gradient(to right, #f5f7fa, #e6f0ff); /* Subtle gradient background */
    transition: box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.Audio-Player:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for a 'lifting' effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .Audio-Player {
        width: 90%; /* Increase width on smaller screens */
    }
}

@media (max-width: 480px) {
    .Audio-Player {
        width: 95%; /* Almost full width on very small screens */
        padding: 10px; /* Adjust padding on smaller screens */
    }
}

/* Add hover and focus effects for buttons within the audio player */
.Audio-Player button:hover, .Audio-Player button:focus {
    background-color: #0056b3; /* Slightly darker on hover/focus */
    color: #ffffff; /* White text for contrast */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.producer-info {
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px;
    padding: 15px;
    border-radius: 12px;
    background-color: #ffffff; /* White background for a clean look */
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items for a balanced layout */
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
}

.producer-info-item {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #f5f7fa; /* Slightly off-white for contrast */
    margin: 5px;
    flex-basis: calc(33.333% - 20px); /* Adjust for better spacing */
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.producer-info-item:hover {
    background-color: #e8f0fe; /* Light blue for hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12); /* Enhanced shadow on hover */
}

/* Additional media queries for responsiveness */
@media (max-width: 768px) {
    .producer-info-item {
        flex-basis: calc(50% - 10px); /* Adjust for medium screens */
    }
}

@media (max-width: 480px) {
    .producer-info-item {
        flex-basis: 100%; /* Full width on small screens */
    }
}

/* Time Display Styles */
.time-display {
    background-color: #f5f7fa;
    color: #2F4F4F;
    font-size: 20px;
    padding: 8px 15px;
    border-radius: 8px;
}

button:disabled {
    background-color: #A9A9A9; /* DarkGray */
    cursor: not-allowed; /* Change the cursor to indicate the button is disabled */
    color: #696969; /* DimGray */
}

button:disabled:hover {
    background-color: #A9A9A9; /* Ensure the hover color remains the same when disabled */
}

.header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tokens-remaining {
    font-family: 'Montserrat', sans-serif;
    font-weight: 525;
    text-align: right;
}
/* Responsive adjustments for medium screens (e.g., tablets) */
@media (max-width: 768px) {
    .tokens-remaining {
        font-size: 14px; /* Smaller font size for medium screens */
        /* Other adjustments if needed */
    }
}

/* Responsive adjustments for small screens (e.g., mobile phones) */
@media (max-width: 480px) {
    .tokens-remaining {
        font-size: 12px; /* Even smaller font size for small screens */
        /* Other adjustments if needed */
    }
}
.account-dropdown {
    position: relative;
    display: inline-block;
}
.header-link {
    color: inherit; /* Inherits the color from the parent element */
    text-decoration: none; /* Removes the underline typically associated with links */
    cursor: pointer; /* Changes the cursor to a pointer to indicate clickability */
}

/* Optional: Add hover effects if you want some interaction indication */
.header-link:hover, .header-link:focus {
    text-decoration: none; /* Example: Underline on hover/focus */
}
/* Subscription Options Styles */
.subscription-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
}

.subscription-tier {
    border: 2px solid #7BC0FF; /* Sky Blue */
    background-color: #f5f7fa; /* Light background color */
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscription-tier h3 {
    margin-top: 0;
    color: #001F3F; /* Navy Blue */
}

.subscription-tier p {
    color: #2F4F4F; /* Slate Gray */
}

.subscription-tier button {
    background-color: #7BC0FF; /* Sky Blue */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.subscription-tier button:hover {
    background-color: #0056b3; /* Darker Blue */
}

.subscription-tier-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px; /* Optional: for rounded image corners */
    margin-bottom: 10px; /* Space between image and tier name */
}
.modal {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Adjust the space between elements */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.15); /* Semi-transparent white for icy effect */
    backdrop-filter: blur(10px); /* Blurry background */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for glass effect */
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    border-radius: 5px;
    width: 400px; /* Adjust width as needed */
}

.modal h2 {
    margin: 0;
    padding-bottom: 20px; /* Add some space below the title */
    color: hsl(211, 73%, 12%); /* Soft blue color for the text */
}

.modal input {
    width: 80%; /* Adjust to your preference */
    padding: 8px; /* Makes the input box taller */
    border: 1px solid rgba(255, 255, 255, 0.6); /* Light border */
    background-color: rgba(255, 255, 255, 0.2); /* Slightly transparent */
    border-radius: 5px;
    color: #000; /* Text color */
}

.modal button {
    background-color: rgba(30, 73, 108, 0.4); /* Semi-transparent Steel Blue */
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: rgba(32, 93, 143, 0.6); /* Darker on hover */
        transform: translateY(-2px); /* Slight lift on hover */
    }
}

.generate-full-beat-button {
    background-color: rgb(224, 224, 224);
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 0; /* Square corners */
}

.generate-full-beat-button:hover {
    background-color: rgb(46, 98, 220)  /* Slightly lighter on hover */
}

.generate-full-beat-button:active {
    background-color: rgb(106, 106, 106); /* Blue when clicked */
}

.account-details-box {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(240, 248, 255, 0.95); /* That's Azure, frosty blue */
    border-radius: 4px; /* Soften those corners */
    padding: 20px; /* Give it some space */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* A subtle shadow to lift it off the page */
    margin: 20px auto; /* Center it with some margin love */
    max-width: 600px; /* Don't let it get too wide */
    border: 1px solid #e0e0e0; /* A light border for that crisp edge */
}

.beats-list {
    background-color: #f0f8ff; /* Light Azure color for background */
    border-radius: 10px; /* Rounded corners */
    margin: 20px auto; /* Centering with margin */
    padding: 20px; /* Space inside the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
    max-width: 600px; /* Maximum width */
    max-height: 300px; /* Maximum height */
    overflow-y: auto; /* Add vertical scrollbar */
}

.beat-item-header, .beat-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Three columns of equal width */
    gap: 10px; /* Space between columns */
    align-items: center;
    padding: 10px;
}

.beat-item-header {
    font-weight: bold; /* Make header text bold */
    border-bottom: 2px solid #ccc; /* Add a bottom border to the header */
    margin-bottom: 10px; /* Space below the header */
}

.beat-item {
    background-color: #e6f7ff; /* Very light blue for each item */
    margin-bottom: 10px; /* Space between items */
    border-radius: 5px; /* Slightly rounded corners for items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for each item */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effects */
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
}
.beat-item:hover {
    background-color: #007bff; /* Highlight blue on hover */
    color: white; /* Change text color to white for better contrast */
    transform: scale(1.05); /* Slightly enlarge the item */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a "pop" effect */
}
.beat-item span {
    overflow-wrap: break-word; /* Ensure long words wrap and don't overflow */
}
.beat-item span {
    margin-right: 10px; /* Space between spans */
}

.beat-item:last-child {
    margin-bottom: 0; /* Remove margin for the last item */
}

.beat-section-editor {
    display: flex;
    flex-direction: row; /* Change to row to place items side-by-side */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Keep the gap for spacing */
    animation: slideUp 0.5s ease forwards;

}

.section-box {
    background-color: #f0f8ff;
    border: 2px solid #ccc;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width for better spacing */
    max-width: 300px; /* Adjust as needed */
    overflow: hidden; /* Prevent overflow */
}

.section-Swap-box {
    background-color: #f0f8ff;
    border: 2px solid #ccc;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width for better spacing */
    max-width: 180px; /* Adjust as needed */
    overflow: hidden; /* Prevent overflow */
}


.drum-selection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: slideUp 0.5s ease forwards;
}

.editor-buttons {
    width: 100%; /* Ensure buttons span the full width */
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 10px;
    padding-top: 7px;
    animation: slideUp 0.5s ease forwards;
}


.editor-button {
    padding: 10px 35px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.editor-button:hover {
    background-color: #007bff; /* Change color on hover */
    color: white;
}

/* Optional: Additional CSS for responsiveness */
@media (max-width: 768px) {
    .section-box {
        width: 100%; /* Full width on smaller screens */
    }

    .beat-section-editor {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }
}
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.drum-selection, .editor-buttons {
    animation: slideUp 0.5s ease forwards;
}

.drum-selection.hide, .editor-buttons.hide {
    animation: fadeOut 1.5s ease forwards;
}


.drum-table {
    width: 48%;
    border: 1px solid #ccc;
    padding: 10px;
}

.section-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.drum-item {
    margin-right: 10px;
    cursor: pointer;
}

.drum-item.removed {
    text-decoration: line-through;
    color: #ff6347; /* Reddish color for removed items */
}
.drum-item.global {
    font-weight: bold;
    color: green;
}
.dropdown-menu {
    background-color: #f0f8ff; /* Light Azure color for background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
    padding: 10px; /* Space inside the container */
    z-index: 1; /* Ensure it's above other content */
    display: none; /* Initially hidden */
    animation: expandDownwards 0.3s ease-out forwards; /* Animation for expanding */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
    margin-top: 5px; /* Space between button and dropdown */
    width: auto; /* Auto width based on content */
    display: flex; /* Use Flexbox */
}

.dropdown-item {
    background-color: transparent; /* Transparent background */
    color: #2F4F4F; /* Slate Gray for text */
    padding: 8px 15px; /* Padding for the items */
    margin: 5px 0; /* Margin between items */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, color 0.3s; /* Transition for hover effect */
    cursor: pointer; /* Cursor to indicate it's clickable */
    width: 100%; /* Full width */
    text-align: center; /* Center-align text */
}
.dropdown-menu.hide {
    animation: collapseUpwards 0.3s ease-out forwards;
}
.dropdown-item:hover {
    background-color: #007bff; /* Electric Blue on hover */
    color: white; /* White text on hover */
}
@keyframes expandDownwards {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}
@keyframes collapseUpwards {
    from {
        transform: scaleY(1);
        opacity: 1;
    }
    to {
        transform: scaleY(0);
        opacity: 0;
    }
}

.beat-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px; /* Reduced gap */
    font-size: 16px;
}

.beat-info p {
    padding: 3px 3px; 
    border-radius: 2px;
}
/* 

/* Beat Title and Structure Styles - newly added Feb 28 2024

*/
.beat-title {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    font-size: 20px; /* Slightly larger font size for emphasis */
    font-weight: bold; /* Make it bold */
    margin: 20px 0; /* Add some vertical spacing */
    background: -webkit-linear-gradient(45deg, #0074D9, #7BC0FF); /* Gradient text color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; /* Apply the gradient to the text */
    text-shadow: 2px 2px 8px rgba(0,0,0,0.2); /* Soft shadow for depth */
    transition: transform 0.3s ease; /* Smooth transition for hover effects */
}

.beat-title:hover {
    transform: scale(1.03); /* Slightly enlarge text on hover */
}

/* Styling for Beat Structure */
.beat-structure {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    font-size: 16px; /* Standard font size for information */
    color: #2F4F4F; /* Slate Gray for a professional look */
    margin: 10px 0; /* Add some vertical spacing */
}

@media (max-width: 480px) {
    .beat-title, .beat-structure {
        justify-content: center;
        font-size: 14px; /* Adjust font size for smaller screens if needed */
    }
}

@media (max-width: 768px) {
    .producer-info-item {
        flex-basis: calc(50% - 10px); /* Adjust for medium screens */
    }
}

@media (max-width: 480px) {
    .producer-info-item {
        flex-basis: 100%; /* Full width on small screens */
    }

    .beat-info {
        grid-template-columns: 1fr; /* Single column layout on small screens */
    }
}

@media (max-width: 768px) {
    .search-input[type="text"] {
        width: 95%; /* Increase width on medium screens */
    }
}

@media (max-width: 480px) {
    .search-input[type="text"] {
        width: 100%; /* Full width on small screens */
        padding: 8px 12px; /* Adjust padding for smaller screens */
    }

    .search-container {
        flex-direction: column; /* Stack elements vertically on small screens */
        align-items: center; /* Center align items */
    }
}

/* Adjust placeholder animation for smaller screens */
@media (max-width: 480px) {
    .placeholder-container {
        top: 17px; /* Adjust top position */
        left: 10px; /* Adjust left position */
        width: calc(100% - 20px); /* Ensure it matches the input width minus padding */
    }

    .placeholder-text {
        font-size: 10px; /* Adjust font size for smaller screens */
    }
}

/* Loading Spinner and Overlay */
/* Spinner Styles */
.loading-spinner {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Overlay Styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(1, 65, 128); /* Dark blue with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    z-index: 1000; /* Ensure it's on top */
}


/* Rights Popup Styles */
.rights-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Adjust this value as needed to ensure it's higher than other elements */
  }
  .rights-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    max-width: 600px;
  }

.header-logo {
    height: 30px; /* Adjust height as needed */
    width: auto;
    vertical-align: middle;
    transition: transform 0.2s ease;
}

.header-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header-link:hover .header-logo {
    transform: scale(1.05); /* Subtle hover effect */
}

/* If you need to adjust the header height */
header {
    display: flex;
    align-items: center;
    /* ... your existing header styles ... */
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
}

.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    font-size: 16px;
    color: #333;
    margin: 5px 0;
}

.progress-step-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    width: 100%;
}

.step-message {
    font-size: 14px;
    color: #666;
    margin: 0;
    padding: 5px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    max-width: 600px;
}

.loading-animation {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

.loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.loading-status {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}

.progress-container {
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
}

.progress-bar-container {
    background-color: #f0f0f0;
    border-radius: 10px;
    height: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.progress-bar {
    background-color: #4CAF50;
    height: 100%;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 12px;
    font-weight: bold;
}

.progress-step-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    width: 100%;
}

.step-message {
    font-size: 14px;
    color: #666;
    line-height: 1.4;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}