.subscription-feature {
    text-align: center;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
}

.subscription-feature h1 {
    color: #ffff;
}

.membership-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.membership-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f7fa;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.membership-card:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow for pop-out effect */
}

.membership-card h2 {
    color: #007bff;
}

.membership-card h3 {
    color: #333;
}

.membership-card ul.features-list {
    list-style: inside; /* Position bullets inside the list */
    text-align: left; /* Align text to the left */
    padding-left: 0; /* Remove default padding */
}

.membership-card ul.features-list li {
    margin-bottom: 10px; /* Add space between list items */
}

.membership-card button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px; /* Add space above the button */
}

.membership-card button:hover {
    background-color: #0056b3;
}
.sign-up-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 13px 26px; /* Increased padding by about 30% */
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px; /* Increased font size by about 30% */
    margin-top: 20px; /* Space above the button */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform to transition */
}

.sign-up-button:hover {
    background-color: #0056b3;
    transform: scale(1.1); /* Slightly enlarge on hover */
    animation: pulse 1.5s infinite; /* Pulse animation */

}

/* Pulse keyframes */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}